<template>
  <div class="px-10">
    <BackFr class="top-5 -right-4 absolute" v-if="isLtr" />
    <BackFa v-if="isRtl" />
    <div class="py-3">
      <div class="flex">
        <div class="w-3/4">
          <div
            :class="{ 'ml-3': isRtl, 'mr-3': isLtr }"
            class="bg-white p-3 border border-gray-200 shadow-lg font-bold text-xl"
          >
            <span :class="{ 'text-rose': isLtr }">{{
              $t('profile_title')
            }}</span>
          </div>
        </div>
        <div class="w-1/4">
          <div
            :class="{ 'text-xl': isRtl, 'text-lg': isLtr }"
            class="p-3 bg-white border text-right border-gray-200 shadow-lg font-bold"
          >
            <span :class="{ 'text-rose': isLtr }"
              >{{ $t('profile_title3') }}
              <span class="text-rose">{{ $t('profile_title2') }}</span></span
            >
          </div>
        </div>
      </div>
      <div class="flex">
        <div class="w-3/4 flex py-3">
          <div class="w-1/3">
            <div
              class="bg-white text-center p-3 relative shadow-lg border-gray-200 border"
            >
              <div class="flex justify-center">
                <img src="../assets/images/user.png" alt="" />
              </div>
              <div class="grid grid-cols-9 mx-auto p-1 pt-0 -mt-1">
                <!-- empty -->
                <div class="contents">
                  <div class="col-start-1 col-end-5 h-4"></div>
                  <div class="col-start-5 col-end-6 mx-auto relative">
                    <div class="h-full w-3 flex items-center justify-center">
                      <div
                        class="h-full w-0.5 bg-gray-300 pointer-events-none"
                      ></div>
                    </div>
                  </div>
                  <div class="col-start-6 col-end-10 h-4"></div>
                </div>
                <!-- one -->
                <div class="contents">
                  <div class="col-start-1 col-end-5 h-6 mb-1">
                    <span class="font-light text-xs mb-1"
                      >{{ $t('profile_fullname') }}
                    </span>
                  </div>
                  <div class="col-start-5 col-end-6 mx-auto relative">
                    <div class="h-full w-3 flex items-center justify-center">
                      <div
                        class="h-full w-0.5 bg-gray-300 pointer-events-none"
                      ></div>
                    </div>
                    <div
                      class="absolute z-10 top-1/2 -mt-3 shadow bg-green-400 h-3 w-3 rounded-full"
                    ></div>
                  </div>
                  <div class="col-start-6 col-end-10 h-6 mb-1">
                    <span class="font-light text-xs mb-1">
                      {{ $t('name_main_page_list') }}</span
                    >
                  </div>
                </div>
                <!-- two -->
                <div class="contents">
                  <div class="col-start-1 col-end-5 h-6 mb-1">
                    <span class="font-light text-xs mb-1">{{
                      $t('profile_care_conditions')
                    }}</span>
                  </div>
                  <div class="col-start-5 col-end-6 mx-auto relative">
                    <div class="h-full w-4 flex items-center justify-center">
                      <div
                        class="h-full w-0.5 bg-gray-300 pointer-events-none"
                      ></div>
                    </div>
                    <div
                      class="absolute z-10 top-1/2 -mt-3 shadow bg-rose h-4 w-4 rounded-full"
                    ></div>
                  </div>
                  <div class="col-start-6 col-end-10 h-6 mb-1">
                    <span class="font-light text-xs mb-1">{{
                      $t('profile_home_care')
                    }}</span>
                  </div>
                </div>
                <!-- three -->
                <div class="contents">
                  <div class="col-start-1 col-end-5 h-6 mb-1">
                    <span class="font-light text-xs mb-1"
                      >{{ $t('alert_card_doctor') }}
                    </span>
                  </div>
                  <div class="col-start-5 col-end-6 mx-auto relative">
                    <div class="h-full w-3 flex items-center justify-center">
                      <div
                        class="h-full w-0.5 bg-gray-300 pointer-events-none"
                      ></div>
                    </div>
                    <div
                      class="absolute z-10 top-1/2 -mt-3 shadow bg-green-400 h-3 w-3 rounded-full"
                    ></div>
                  </div>
                  <div class="col-start-6 col-end-10 h-6 mb-1">
                    <span class="font-light text-xs mb-1">{{
                      $t('profile_doctor_name')
                    }}</span>
                  </div>
                </div>
                <!-- empty -->
                <div class="contents">
                  <div class="col-start-1 col-end-5 h-4"></div>
                  <div class="col-start-5 col-end-6 mx-auto relative">
                    <div class="h-full w-3 flex items-center justify-center">
                      <div
                        class="h-full w-0.5 bg-gray-300 pointer-events-none"
                      ></div>
                    </div>
                  </div>
                  <div class="col-start-6 col-end-10 h-4"></div>
                </div>
              </div>
              <div class="-mt-3 text-center">
                <i class="fas fa-caret-down text-red-600"></i>
                <div class="-mt-2 text-xs font-bold">
                  {{ $t('profile_clinical') }}
                </div>
              </div>
              <div
                class="flex justify-between items-center text-xs text-gray-600 mx-8 mt-2"
              >
                <small>{{ $t('profile_clinical1') }}</small>
                <small>{{ $t('profile_clinical2') }}</small>
              </div>
              <div class="bg-gray-200 mx-3 h-1 rounded-xl">
                <div class="bg-green-500 h-1 rounded-xl"></div>
              </div>
              <div
                class="flex justify-center items-center m-3 p-1 border border-gray-300"
              >
                <BaseBadge class="w-5 h-4 p-3" color="green">
                  <i class="text-white far fa-long-arrow-alt-left"></i
                ></BaseBadge>
                <small class="text-xs m-1">
                  {{ $t('profile_correspondence') }}
                </small>
              </div>
              <div class="flex justify-between mx-3">
                <BaseBadge
                  :class="{ 'w-20 h-20': isLtr, 'w-12 h-12': isRtl }"
                  class="rounded-full p-3"
                  color="rose"
                >
                  <BaseBadge
                    @click="changeRoute('PatientEnvironment')"
                    :class="{ 'w-16 h-16': isLtr, 'w-9 h-9': isRtl }"
                    class="cursor-pointer rounded-full p-3"
                    color="white"
                  >
                    <span class="break-words text-xs">{{
                      $t('profile_environment')
                    }}</span>
                  </BaseBadge>
                </BaseBadge>
                <BaseBadge
                  :class="{ 'w-20 h-20': isLtr, 'w-12 h-12': isRtl }"
                  class="p-3"
                  color="purple"
                >
                  <BaseBadge
                    @click="changeRoute('Information')"
                    :class="{ 'w-16 h-16': isLtr, 'w-9 h-9': isRtl }"
                    class="cursor-pointer p-3"
                    color="white"
                  >
                    <span class="break-words text-xs">{{
                      $t('profile_file')
                    }}</span>
                  </BaseBadge>
                </BaseBadge>
              </div>
              <button
                class="text-sm bg-gradient-to-r from-cyan-500 to-cyan-300 my-3 px-3 py-2 text-white font-bold rounded-lg"
              >
                {{ $t('profile_edit') }}
              </button>
            </div>
          </div>

          <div class="w-2/3 px-3">
            <div class="p-3 bg-white shadow-lg border-gray-200 border">
              <div class="flex items-center mb-2">
                <div class="w-1/4">
                  <label
                    class="block text-sm text-gray-500 font-bold text-right mb-1 pr-4"
                    for="inline-full-name"
                  >
                    {{ $t('profile_fullname') }}
                  </label>
                </div>
                <div class="w-3/4">
                  <input
                    class="appearance-none border-2 border-gray-200 rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                    id="inline-full-name"
                    type="text"
                    :value="$t('name_main_page_list')"
                  />
                </div>
              </div>
              <!--  -->
              <div class="flex items-center mb-2">
                <div class="w-1/4">
                  <label
                    class="block text-sm text-gray-500 font-bold text-right mb-1 pr-4"
                    for="inline-full-name"
                    >{{ $t('profile_gender') }}
                  </label>
                </div>
                <div class="w-3/4">
                  <label class="inline-flex items-center">
                    <input
                      type="radio"
                      class="form-radio text-green-600"
                      name="accountType"
                      value="personal"
                      checked
                    />
                    <span class="mx-2">{{ $t('female') }}</span>
                  </label>
                  <label class="inline-flex items-center mx-16">
                    <input
                      type="radio"
                      class="form-radio text-green-600"
                      name="accountType"
                      value="busines"
                    />
                    <span class="mx-2">{{ $t('man') }}</span>
                  </label>
                </div>
              </div>
              <!--  -->
              <div class="flex items-center mb-2">
                <div class="w-1/4">
                  <label
                    class="block text-sm text-gray-500 font-bold text-right mb-1 pr-4"
                    for="inline-full-name"
                  >
                    {{ $t('birthday_title') }}
                  </label>
                </div>
                <div class="w-3/4">
                  <input
                    class="appearance-none border-2 border-gray-200 rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                    id="inline-full-name"
                    type="text"
                    :value="$t('birthday')"
                  />
                </div>
              </div>
              <!--  -->
              <div class="flex items-center mb-2">
                <div class="w-1/4">
                  <label
                    class="block text-sm text-gray-500 font-bold text-right mb-1 pr-4"
                    for="inline-full-name"
                  >
                    {{ $t('place_birth_title') }}
                  </label>
                </div>
                <div class="w-3/4">
                  <input
                    class="appearance-none border-2 border-gray-200 rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                    id="inline-full-name"
                    type="text"
                    :value="$t('place_birth')"
                  />
                </div>
              </div>
              <!--  -->
              <div class="flex items-center mb-2">
                <div class="w-1/4">
                  <label
                    class="block text-sm text-gray-500 font-bold text-right mb-1 pr-4"
                    for="inline-full-name"
                  >
                    {{ $t('post_address_title') }}
                  </label>
                </div>
                <div class="w-3/4">
                  <input
                    class="appearance-none border-2 border-gray-200 rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                    id="inline-full-name"
                    type="text"
                    :value="$t('post_address')"
                  />
                </div>
              </div>
              <!--  -->
              <div class="flex items-center mb-2">
                <div class="w-1/4">
                  <label
                    class="block text-sm text-gray-500 font-bold text-right mb-1 pr-4"
                    for="inline-full-name"
                  >
                    {{ $t('mobile_title') }}
                  </label>
                </div>
                <div class="w-3/4">
                  <input
                    class="appearance-none border-2 border-gray-200 rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                    id="inline-full-name"
                    type="text"
                    value=""
                  />
                </div>
              </div>
              <!--  -->
              <div class="flex items-center mb-2">
                <div class="w-1/4">
                  <label
                    class="block text-sm text-gray-500 font-bold text-right mb-1 pr-4"
                    for="inline-full-name"
                  >
                    {{ $t('phone_title') }}
                  </label>
                </div>
                <div class="w-3/4">
                  <input
                    class="appearance-none border-2 border-gray-200 rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                    id="inline-full-name"
                    type="text"
                    value="0143255499"
                  />
                </div>
              </div>
              <!--  -->
              <div class="flex items-center mb-2">
                <div class="w-1/4">
                  <label
                    class="block text-sm text-gray-500 font-bold text-right mb-1 pr-4"
                    for="inline-full-name"
                    >{{ $t('email_title') }}
                  </label>
                </div>
                <div class="w-3/4">
                  <input
                    class="appearance-none border-2 border-gray-200 rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                    id="inline-full-name"
                    type="text"
                    value="example@local.com"
                  />
                </div>
              </div>
              <!-- -->
              <div class="flex items-center mb-2">
                <div class="w-1/4">
                  <label
                    class="block text-sm text-gray-500 font-bold text-right mb-1 pr-4"
                    for="inline-full-name"
                  >
                    {{ $t('marriage_title') }}
                  </label>
                </div>
                <div class="w-3/4">
                  <div class="flex items-center">
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox text-indigo-600"
                        checked
                      />
                      <span class="mx-2">{{ $t('single') }}</span>
                    </label>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox text-indigo-600"
                      />
                      <span class="mx-2">{{ $t('divorced') }}</span>
                    </label>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox text-indigo-600"
                      />
                      <span class="mx-2">{{ $t('widow') }}</span>
                    </label>
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        class="form-checkbox text-indigo-600"
                      />
                      <span class="mx-2">{{ $t('singel2') }}</span>
                    </label>
                  </div>
                </div>
              </div>
              <!--  -->
              <div class="flex items-center mb-2">
                <div class="w-1/4">
                  <label
                    class="block text-sm text-gray-500 font-bold text-right mb-1 pr-4"
                    for="inline-full-name"
                    >{{ $t('taking_care') }}
                  </label>
                </div>
                <div class="w-3/4">
                  <input
                    class="appearance-none border-2 border-gray-200 rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                    id="inline-full-name"
                    type="text"
                    :value="$t('profile_home_care')"
                  />
                </div>
              </div>
              <!--  -->
              <div class="flex items-center mb-2">
                <div class="w-1/4">
                  <label
                    class="block text-sm text-gray-500 font-bold text-right mb-1 pr-4"
                    for="inline-full-name"
                    >{{ $t('profile_doctor') }}
                  </label>
                </div>
                <div class="w-3/4">
                  <input
                    class="appearance-none border-2 border-gray-200 rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                    id="inline-full-name"
                    type="text"
                    :value="$t('profile_doctor_name')"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 1.2 -->
        <!-- 1.3 -->
        <div class="bg-white w-1/4 pt-3 text-center">
          <div class="px-6 py-4 border border-gray-200 shadow-lg text-sm">
            <div class="flex justify-center mb-3">
              <img src="../assets/images/usericon.png" alt="" />
            </div>
            <div class="py-2 px-1 mx-auto border border-gray-200">
              <div class="flex items-center">
                <span class="text-xs text-gray-400">{{
                  $t('profile_fullname')
                }}</span>
                <span class="mx-auto">{{ $t('name_user') }}</span>
              </div>
            </div>
            <div class="py-2 mt-3 px-1 mx-auto border border-gray-200">
              <div class="flex items-center">
                <span class="text-xs text-gray-400">{{
                  $t('address_home_title')
                }}</span>
                <span class="mx-auto"> {{ $t('address_home') }}</span>
              </div>
            </div>
            <div class="p-1 mt-3 mx-auto border border-gray-200">
              <div class="flex items-center">
                <span class="text-xs text-gray-400">
                  <i class="text-xl mr-2 fal fa-angle-down"></i>
                </span>
                <span class="mx-auto text-gray-400">{{ $t('boy') }}</span>
              </div>
            </div>
            <div class="py-2 mt-3 px-1 mx-auto border border-gray-200">
              <div class="flex items-center">
                <span class="text-xs text-gray-400">{{
                  $t('mobile_title')
                }}</span>
                <span class="mx-auto">0679999899</span>
              </div>
            </div>
            <div class="py-2 mt-3 px-1 mx-auto border border-gray-200">
              <div class="flex items-center">
                <span class="text-xs text-gray-400">{{
                  $t('phone_title')
                }}</span>
                <span class="mx-auto">0155995599</span>
              </div>
            </div>
            <div class="py-2 mt-3 px-1 mx-auto border border-gray-200">
              <div class="flex items-center">
                <span class="text-xs text-gray-400">{{
                  $t('admin_email')
                }}</span>
                <span class="mx-auto text-xs">example@local.com</span>
              </div>
            </div>
            <div class="flex justify-center mt-8 mb-4">
              <button
                class="bg-gradient-to-r from-cyan-500 to-cyan-300 text-white text-xs ml-3 px-2 py-1"
              >
                {{ $t('edit_admin') }}
              </button>
              <button
                v-if="isRtl"
                class="border border-gray-300 text-rose px-2 py-1 text-xs"
              >
                افزودن سرپرست جدید
              </button>
            </div>
          </div>
        </div>
        <!-- 1.3 -->
      </div>
      <!-- 1 -->
    </div>
  </div>
</template>

<script>
import BackFa from '../components/back/BackFa.vue'
import BackFr from '../components/back/BackFr.vue'
export default {
  components: { BackFr, BackFa },
  methods: {
    changeRoute(route) {
      this.$router.push({ name: route })
    },
  },
}
</script>

<style></style>
